import "./main.css"

import React from 'react'
import { AppBar,
        Divider,
        IconButton,
        Menu,
        MenuItem,
        Toolbar
        } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import { Cajas } from './cajas/contenedor'
import { Clientes } from './clientes/contenedor'
import { Movimientos as MovimientosCaja } from './cajas/movimientos/contenedor'
import { Nuevo as NuevoMovimientoCaja } from './cajas/movimientos/nuevo/contenedor'
import { Productos } from './productos/contenedor'
import { Mesas } from './mesas/contenedor'
import { Usuarios } from './usuarios/contenedor'
import { MiEmpresa } from './empresa/miEmpresa'
import { Reportes } from './reportes/contenedor'
import { Configuraciones } from './configuraciones'
import { Traduccion } from '../comp/Traduccion'
import { Acceso, NivelAcceso } from '../utils/Seguridad'
import { logout } from "../services/servUsuarios"
import { inicializarFormatters } from "../utils/Formatter"

export function Main() {

    let location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    inicializarFormatters()

    const pantallas = [
        {grupo: 1, link: '/cajas', text: 'ViewCajas', comp: <Cajas/>, esMenu: true},
        {grupo: 1, link: '/mesas', text: 'ViewMesas', comp: <Mesas/>, esMenu: true},
        {grupo: 1, link: '/productos', text: 'ViewProductos', comp: <Productos/>, esMenu: true},
        {link: '/cajas/movimientos', comp: <MovimientosCaja/>, esMenu: false},
        {link: '/cajas/movimientos/nuevo', comp: <NuevoMovimientoCaja/>, esMenu: false},
        {grupo: 2, link: '/empresa', text: 'ViewMiEmpresa', comp: <MiEmpresa/>, esMenu: true},
        {grupo: 2, link: '/configuraciones', text: 'ViewConfiguraciones', comp: <Configuraciones/>, esMenu: true},
        {grupo: 2, link: '/reportes', text: 'ViewReportesCaja', comp: <Reportes/>, esMenu: true},
        {grupo: 1, link: '/clientes', text: 'ViewClientes', comp: <Clientes/>, esMenu: true},
        {grupo: 1, link: '/usuarios', text: 'ViewUsuarios', comp: <Usuarios/>, esMenu: true}
    ];

    const menues = pantallas.filter(item => item.esMenu && Acceso(item.text, NivelAcceso().VER))
                            .sort((a,b) => 
                                a.grupo-b.grupo!==0
                                    ? a.grupo-b.grupo
                                    : Traduccion(a.text).localeCompare(Traduccion(b.text))
                            )

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const cerrarSesion = () => {
        logout()
    }

    return (
            <div>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" 
                                    aria-label="menu" onClick={openMenu}>
                            <MenuIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={closeMenu}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                              transformOrigin={{vertical: 'top', horizontal: 'right'}}
                              >
                            {menues.map((menu, index) => (
                                <div key={index}>
                                    {index > 0 && menu.grupo > menues[index-1].grupo &&
                                    <Divider variant="middle" />
                                    }
                                    <MenuItem key={menu.link}
                                            onClick={closeMenu}
                                            component={Link}
                                            to={menu.link}>
                                    {Traduccion(menu.text)}
                                    </MenuItem>
                                </div>
                            ))}
                            <Divider variant="middle" />
                            <MenuItem component={Link} to={'/login'} onClick={cerrarSesion}>
                                {Traduccion('TXT_CERRAR_SESION')}
                            </MenuItem>
                        </Menu>
                        <h3>Bienvenido</h3>
                    </Toolbar>
                </AppBar>
                <div className="mainContent">
                    <Routes location={location}>
                    {pantallas.map(unaPantalla => (
                        <Route key={unaPantalla.link} 
                                path={unaPantalla.link} 
                                element={unaPantalla.comp}
                                exact/>
                                    ))}
                        <Route key={'/reportes'} 
                                path={'/reportes'} 
                                element={<Reportes/>}
                                exact/>     
                    </Routes>
                </div>
            </div>
            )

}
