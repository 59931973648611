import { Config } from '../config/Sesion'
import { FormatoFechaHora } from './Constantes'
import { FormatDateTo } from './DateUtil'

let config
let locale
let currency
let internalFormatImporte
let internalFormatCantidad

export const inicializarFormatters = () => {
    config = Config()
    locale = config.SEPARADOR_DECIMAL === ',' ? 'es-AR' : 'en-US'
    currency = config.SEPARADOR_DECIMAL === ',' ? 'ARS' : 'USD'
    internalFormatImporte = Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: config.DECIMALES_IMPORTE,
        maximumFractionDigits: config.DECIMALES_IMPORTE
    })
    internalFormatCantidad = Intl.NumberFormat(locale, {
        minimumFractionDigits: config.DECIMALES_CANTIDAD,
        maximumFractionDigits: config.DECIMALES_CANTIDAD
    })
}

export const FormatFechaHora = (fecha) => FormatDateTo(fecha, FormatoFechaHora.ARGENTINA)
export const FormatCodigo = (codigo) => codigo

export const FormatImporte = (importe) => internalFormatImporte
    .format(Object.is(importe, -0) ? 0 : importe)

export const FormatCantidad = (cantidad) => internalFormatCantidad
    .format(cantidad)