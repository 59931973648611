import React from "react"
import {
    reporteDeudores,
    reporteEstadoCtaCte,
    reporteEvolucionClientes,
    reporteGastadores
} from "../../services/servClientes"
import { InputPeriodo} from "../../comp/InputFecha"
import { Traduccion } from "../../comp/Traduccion"
import { Normal } from "../../comp/Texto"
import { GraficoBarras, GraficoGauge } from "../../comp/Graficos"
import { Card, CardContent, CardHeader, Grid, Stack } from "@mui/material"
import { FormatImporte } from "../../utils/Formatter"

export const ReportesClientes = () => {

    let defaultPeriodo = new Date()
    defaultPeriodo.setDate(1)

    const [periodo, setPeriodo] = React.useState(defaultPeriodo) //busco datos del mes en curso

    const [resServEstadoCtaCte, setResServEstadoCtaCte] = React.useState({prestado: 0, total: undefined})
    const [isServEstadoCtaCteLoading, setIsServEstadoCtaCteLoading] = React.useState(false)

    const [resServDeudores, setResServDeudores] = React.useState([])
    const [isServDeudoresLoading, setIsServDeudoresLoading] = React.useState(false)

    const [resServGastadores, setResServGastadores] = React.useState([])
    const [isServGastadoresLoading, setIsServGastadoresLoading] = React.useState(false)

    const [resServEvolucionClientes, setResServEvolucionClientes] = React.useState([])
    const [isServEvolucionClientesLoading, setIsServEvolucionClientesLoading] = React.useState(false)

    const armarEstadoCtaCte = (data) => resServEstadoCtaCte
    const formatLabelEstadoCtaCte = (valor) => FormatImporte(valor)

    const armarDeudores = (data) => data.map(d => ({
        clienteId: d.cliente.id,
        clienteNombre: d.cliente.nombre,
        importe: -1 * d.importe
    }))
    const formatLabelDeudores = (labelValue) => labelValue.split(' ').join('\n')
    const formatDataDeudores = (dataValue) => FormatImporte(dataValue)
    const dataKeysAndLabelsDeudores = {
        importe: Traduccion('TXT_IMPORTE')
    }

    const armarGastadores = (data) => data.map(d => ({
        clienteId: d.cliente.id,
        clienteNombre: d.cliente.nombre,
        importe: d.importe
    }))
    const formatLabelGastadores = (labelValue) => labelValue.split(' ').join('\n')
    const formatDataGastadores = (dataValue) => FormatImporte(dataValue)
    const dataKeysAndLabelsGastadores = {
        importe: Traduccion('TXT_IMPORTE')
    }

    const armarEvolucionClientes = (data) => {
        const periodos = []
        for (let i = 11; i >= 0; i--) {
            const calculada = new Date(defaultPeriodo.getFullYear(), defaultPeriodo.getMonth() - i, 1)
            periodos.push(calculada.getFullYear()*100 + calculada.getMonth()+1)
        }
        return periodos.map(p => {
            const datosPeriodosAnteriores = data.filter(e => e.periodo < p)
            const datosPeriodoActual = data.filter(e => e.periodo === p)
            let viejos = 0
            let nuevos = 0
            datosPeriodoActual.forEach(d => {
                if (datosPeriodosAnteriores.some(e => e.cliente.id === d.cliente.id)) {
                    viejos++
                } else {
                    nuevos++
                }
            })
            return {
                periodo: p,
                viejos,
                nuevos
            }
        })
    }
    const formatLabelEvolucionClientes = (labelValue) => labelValue.toString()
    const formatDataEvolucionClientes = (dataValue) =>
        `${dataValue} ${Traduccion(dataValue === 1 ? 'TXT_CLIENTE' : 'TXT_CLIENTES')}`
    const dataKeysAndLabelsEvolucionClientes = {
        viejos: Traduccion('TXT_VIEJOS'),
        nuevos: Traduccion('TXT_NUEVOS')
    }

    //para los que renderizan solo a primera carga
    React.useEffect(()=> {
        setIsServEstadoCtaCteLoading(true)
        reporteEstadoCtaCte().then(res => {
            setResServEstadoCtaCte(res.data)
            setIsServEstadoCtaCteLoading(false)
        })
        setIsServDeudoresLoading(true)
        reporteDeudores().then(res => {
            setResServDeudores(res.data)
            setIsServDeudoresLoading(false)
        })
    }, [])

    //para cuando cambia el periodo
    React.useEffect(()=> {
        let filtros = {periodo}
        setIsServGastadoresLoading(true)
        reporteGastadores(filtros).then(res => {
            setResServGastadores(res.data)
            setIsServGastadoresLoading(false)
        })
    }, [periodo])
    
    //para el histórico
    React.useEffect(()=> {
        let filtros = {}
        setIsServEvolucionClientesLoading(true)
        reporteEvolucionClientes(filtros).then(res => {
            setResServEvolucionClientes(res.data)
            setIsServEvolucionClientesLoading(false)
        })
    }, [])


    return <>
        <Normal>{Traduccion('TXT_ESTADO_ACTUAL')}</Normal>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm='auto'>
                <Card>
                    <CardHeader title={Traduccion('TXT_ESTADO_CTA_CTE')} />
                    <CardContent>
                        <GraficoGauge
                            value={armarEstadoCtaCte(resServEstadoCtaCte).prestado}
                            valueMax={armarEstadoCtaCte(resServEstadoCtaCte).total}
                            isLoading={isServEstadoCtaCteLoading}
                            labelFormatter={formatLabelEstadoCtaCte}
                            />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_CLIENTES_MAS_DEUDORES')} />
                    <CardContent>
                        <GraficoBarras dataset={armarDeudores(resServDeudores)}
                                        isLoading={isServDeudoresLoading}
                                        labelKey='clienteNombre'
                                        labelFormatter={formatLabelDeudores}
                                        dataAxisDesc={Traduccion('TXT_IMPORTE')}
                                        dataKeysAndLabels={dataKeysAndLabelsDeudores}
                                        dataFormatter={formatDataDeudores}
                                        horizontal
                                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <br/><br/>
        <Stack direction='row' alignItems='center' spacing={2}>
            <Normal>{Traduccion('TXT_ANALISIS_DE')}</Normal>
            <div><InputPeriodo label={Traduccion('TXT_PERIODO')} value={periodo} onChange={setPeriodo}/></div>
        </Stack>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_CLIENTES_MAS_GASTADORES')} />
                    <CardContent>
                        <GraficoBarras dataset={armarGastadores(resServGastadores)}
                                        isLoading={isServGastadoresLoading}
                                        labelKey='clienteNombre'
                                        labelFormatter={formatLabelGastadores}
                                        dataAxisDesc={Traduccion('TXT_IMPORTE')}
                                        dataKeysAndLabels={dataKeysAndLabelsGastadores}
                                        dataFormatter={formatDataGastadores}
                                        horizontal
                                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <br/><br/>
        <Normal>{Traduccion('TXT_ANALISIS_HISTORICO')}</Normal>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={'auto'}>
                <Card>
                    <CardHeader title={Traduccion('TXT_EVOLUCION_CLIENTES')} />
                    <CardContent>
                        <GraficoBarras dataset={armarEvolucionClientes(resServEvolucionClientes)}
                                        isLoading={isServEvolucionClientesLoading}
                                        labelKey='periodo'
                                        labelFormatter={formatLabelEvolucionClientes}
                                        dataAxisDesc={Traduccion('TXT_CANTIDAD_UN')}
                                        dataKeysAndLabels={dataKeysAndLabelsEvolucionClientes}
                                        dataFormatter={formatDataEvolucionClientes}
                                        stack='total'
                                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>
}