import React from 'react'
import ListIcon from '@mui/icons-material/List'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { DataTable } from '../../comp/DataTableV2'
import { Traduccion } from '../../comp/Traduccion'
import {
    listarClientes,
    agregarCliente,
    modificarCliente,
    borrarClientes,
    reactivarClientes
} from '../../services/servClientes'
import { ModalDelete } from '../../comp/ModalDelete'
import { Acciones } from '../../utils/Acciones'
import { TipoDato } from '../../utils/Constantes'
import { Detalle, Filtros, ModalAjustes, ModalTransacciones } from './contenido'
import { Acceso, NivelAcceso } from '../../utils/Seguridad'

const vista = 'ViewClientes'

export const Clientes = () => {

    const tableConfig = {
        title: 'ViewClientes',
        headers: ['TXT_NUMERO', 'TXT_NOMBRE', 'TXT_TOPE_CREDITO', 'TXT_SALDO', 'TXT_CREDITO_DISPONIBE'],
        types: [TipoDato.NUMERO, TipoDato.CADENA, TipoDato.IMPORTE, TipoDato.IMPORTE, TipoDato.IMPORTE],
        access: ['numero', 'nombre', 'topeCredito', 'saldo', 'disponible'],
        rowId: 'id'
    }

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])
    const [tableFilters, setTableFilters] = React.useState({activo: true})

    const [isTransaccionesOpen, setIsTransaccionesOpen] = React.useState(false)
    const [isAjusteOpen, setIsAjusteOpen] = React.useState(false)

    const puedeEditar = Acceso(vista, NivelAcceso().EDITAR)

    const handleDetailOpen = (action, selection) => {
        setDetailAction(action)
        switch (action) {
            case 'C-TRA':
                setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
                setIsTransaccionesOpen(true)
                break
            case 'C-AJU':
                setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
                setIsAjusteOpen(true)
                break
            case Acciones().DEL:
            case Acciones().REAC:
                setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
                setIsDelReacOpened(true)
                break
            default:
                setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
                setIsDetailOpened(true)
                break
        }
    }

    const handleDetailSubmit = (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            setIsAjusteOpen(false)
            return
        }
        switch (detailAction) {
            case Acciones().ADD:
                agregarCliente(obj).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    obj.id = res.data.id
                    obj.activo = true
                    setTableSelection([])
                    setTableData([obj])
                    setIsDetailOpened(false)
                })
                break
            case Acciones().EDIT:
                obj.id = tableSelection[0].id
                modificarCliente(obj).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    Object.keys(obj).forEach(prop => tableSelection[0][prop] = obj[prop])
                    setIsDetailOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().REAC:
                reactivarClientes(tableSelection.map(e => e.id)).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    tableSelection.forEach(e => { e.activo = true })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            case Acciones().DEL:
                borrarClientes(tableSelection.map(e => e.id)).then(res => {
                    if (res.errores) {
                        mostrarErroresOperacion(res.errores)
                        return
                    }
                    tableSelection.forEach(e => { e.activo = false })
                    setIsDelReacOpened(false)
                    setTableSelection([])
                })
                break
            default: return
        }
    }

    const mostrarErroresOperacion = (errores) => {
        Array.isArray(errores)
            ? alert(Traduccion('MSJ_OPERACION_FALLIDA') + ':\r* ' + errores.map(d => Traduccion(d.codigo)).join('\r* '))
            : alert(Traduccion('ERR_INESPERADO'))
    }

    const handleSearchSubmit = (submit, filters) => {
        if (submit) {
            setTableFilters(filters);
        }
        setIsSearchOpened(false)
    }

    const defineMoreActions = () => {
        let otherActions = [
            {
                cond: 'singleSelect',
                label: 'TXT_TRANSACCIONES',
                icon: <ListIcon />,
                action: 'C-TRA'
            }]
        if (puedeEditar) {
            otherActions.push(
                {
                    cond: 'singleSelect',
                    label: 'TXT_AJUSTAR_SALDO',
                    icon: <AttachMoneyIcon />,
                    action: 'C-AJU'
                }
            )
        }
        return otherActions
    }

    const formatDataForTable = (datos) => {
        datos.forEach(d => d.disponible = d.topeCredito + d.saldo)
        return //agregar funcionalidad si hace falta
    }

    const handleCloseAjustes = (res) => {
        setIsAjusteOpen(false)
        if (res) {
            let auxDatosTabla = [...tableData]
            let unCliente = auxDatosTabla.find(e => e.id === res.cliente.id)
            if (unCliente) {
                unCliente.saldo = unCliente.saldo + res.importe
                formatDataForTable(auxDatosTabla)
                setTableData(auxDatosTabla)
            }
        }
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        listarClientes(tableFilters)
            .then(res => {
                formatDataForTable(res.data)
                setTableData(res.data)
                setIsTableLoading(false)
            })
    }, [tableFilters])

    return <div>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tableData}
            isLoading={isTableLoading}
            openDetail={handleDetailOpen}
            openSearch={() => setIsSearchOpened(true)}
            otherActions={defineMoreActions}
            hasAdd={puedeEditar}
            hasEdit={puedeEditar}
            hasReac={puedeEditar}
            hasDel={puedeEditar} />
        <Filtros isOpen={isSearchOpened}
                 onSubmit={handleSearchSubmit}
                 filtros={tableFilters}/>
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <ModalTransacciones isOpen={isTransaccionesOpen}
            seleccion={tableSelection[0]}
            onSubmit={e => setIsTransaccionesOpen(false)} />
        <ModalAjustes isOpen={isAjusteOpen}
            seleccion={tableSelection[0]}
            onSubmit={e => handleCloseAjustes(e)} />
    </div>
}