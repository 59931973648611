import React from "react"
import { DataTable } from "../../../../comp/DataTable"
import { ModalDelete } from "../../../../comp/ModalDelete"
import { Traduccion } from "../../../../comp/Traduccion"
import { Acciones } from "../../../../utils/Acciones"
import { TipoDato } from '../../../../utils/Constantes'
import { Detalle } from "./contenido"
import { agregarMotivoMovimiento, borrarMotivosMovimiento, listarMotivosMovimiento, modificarMotivoMovimiento, reactivarMotivosMovimiento } from "../../../../services/servCajas"
import { Messages } from "../../../../comp/Messages2"

export const MotivosDeMovimiento = () => {

    const [detailAction, setDetailAction] = React.useState(Acciones().ADD)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const [resServicio, setResServicio] = React.useState()

    const handleDetailOpen = (action, selection) => {
        setResServicio()
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = async (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        let res
        switch (detailAction) {
            case Acciones().ADD:
                res = await agregarMotivoMovimiento(obj)
                if (!res.errores) {
                    obj.id = res.data.id
                    obj.activo = true
                    tableData.unshift(obj)
                    formatDataForTable([obj])
                }
                break
            case Acciones().EDIT:
                res = await modificarMotivoMovimiento(obj)
                if (!res.errores) {
                    obj.formasDePago.filter(f => !f.relationId).forEach(f => 
                        f.relationId = res.data.formasDePago.find(fr => fr.id === f.id).relationId
                    )
                    tableSelection[0].nombre = obj.nombre
                    tableSelection[0].signo = obj.signo
                    tableSelection[0].formasDePago = obj.formasDePago
                    formatDataForTable([tableSelection[0]])
                }
                break
            case Acciones().DEL:
                res = await borrarMotivosMovimiento(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = false })
                break
            case Acciones().REAC:
                res = await reactivarMotivosMovimiento(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = true })
                break
            default: return
        }
        setResServicio(res)
        if (!res.errores) {
            setTableSelection([])
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
        }
    }

    const formatDataForTable = (datos = []) => {
        datos.forEach(e => {
            e.nombre = e.id > 0 ? e.nombre : Traduccion(e.nombre)
            e.tipoOperacion = e.signo > 0 ? Traduccion('TXT_INGRESO') : Traduccion('TXT_EGRESO')
            e.formasDePago.forEach(f =>
                f.nombre = f.id < 0
                    ? Traduccion(f.nombre)
                    : f.nombre
            )
            e.formasDePago.sort((e1, e2) => e1.nombre.localeCompare(e2.nombre))
            //en casos puntuales indico que muestre "Todas" como forma de pago
            e.formasDePagoTxt = [-3, -2, 1].includes(e.id)
                ? Traduccion('TXT_TODAS')
                : e.formasDePago.map(unaForma => unaForma.nombre).join(', ')
        })
        datos.sort((e1, e2) => e1.nombre.localeCompare(e2.nombre))
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        setResServicio()
        listarMotivosMovimiento({ incluirFormasDePago: true }).then(res => {
            formatDataForTable(res.data) //a futuro usar data.objetoOperado
            setTableData(res.data)
            res.errores && setResServicio(res)
            setIsTableLoading(false)
        })
    }, [])

    return <>
        <DataTable title='TXT_MOTIVOS_MOVIMIENTO'
            headers={['TXT_NOMBRE', 'TXT_TIPO_MOVIMIENTO', 'TXT_FORMAS_DE_PAGO']}
            types={[TipoDato.CADENA, TipoDato.CADENA, TipoDato.CADENA]}
            access={['nombre', 'tipoOperacion', 'formasDePagoTxt']}
            data={tableData}
            isLoading={isTableLoading}
            rowId={'id'}
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <Messages resServicio={resServicio} accion={detailAction} />
    </>
}