import React from 'react'

import { DataTable } from '../../../comp/DataTableSimple'
import { ModalDetail } from '../../../comp/ModalDetail'
import { Traduccion } from '../../../comp/Traduccion'

import { Acciones } from '../../../utils/Acciones'
import { TipoDato } from '../../../utils/Constantes'

import { listarEventosCaja } from '../../../services/servCajas'

const txtEsApertura = Traduccion('TXT_APERTURA')
const txtEsCierre = Traduccion('TXT_CIERRE')

export const HistoricoCaja = ({ idCaja, mostrar, handleClose }) => {
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (mostrar) {
            setLoading(true)
            listarEventosCaja({ id: idCaja }).then(res => {
                setData(res.data.map(d => ({
                    ...d,
                    tipoMovimiento: d.esApertura ? txtEsApertura : txtEsCierre
                })))
                setLoading(false)
            })
        }
    }, [idCaja, mostrar])

    return (
        <ModalDetail accion={Acciones().SEE} isOpen={mostrar} onSubmit={handleClose} customTitle="TXT_HISTORICO" maxWidth="xl">
            <DataTable headers={['TXT_TIPO_MOVIMIENTO', 'TXT_FECHA', 'TXT_USUARIO']}
                types={[TipoDato.CADENA, TipoDato.FECHA, TipoDato.CADENA]}
                access={['tipoMovimiento', 'fechaHora', 'usuario.nombre']}
                data={data}
                isLoading={loading} />
        </ModalDetail>
    )
}