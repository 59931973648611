export const TipoDato = Object.freeze(
    {
        CODIGO: Symbol(),
        CADENA: Symbol(),
        CANTIDAD: Symbol(),
        IMPORTE: Symbol(),
        FECHA: Symbol(),
        NUMERO: Symbol()
    }
)

export const NivelAcceso = Object.freeze(
    {
        VER: 1,
        AGREGAR: 2,
        EDITAR: 3,
        BORRAR_REINSERTAR: 4,
        CONTROLAR_TODO: 5
    }
)

export const FormatoFechaHora = Object.freeze(
    {
        ARGENTINA: 'DD/MM/YYYY HH:mm:ss'
    }
)

export const Acciones = Object.freeze(
    {
        ADD: 'TXT_AGREGAR',
        EDIT: 'TXT_EDITAR',
        DEL: 'TXT_BORRAR',
        SEE: 'TXT_VER',
        REAC: 'TXT_REACTIVAR'
    }
)