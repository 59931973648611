export const beginDayJson = (aDate) => {
    return dateJson(aDate) + " 00:00:00";
};

export const endDayJson = (aDate) => {
    return dateJson(aDate) + " 23:59:59";
};

export const dateJson = (aDate) => {
    return aDate === null ? null : aDate.substr(0, 10);
};

export const FormatDateTo = (aDate, format) => {
    if (!aDate) return '';
    
    let date = new Date(Date.parse(aDate.split('[UTC]')[0]));
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    return [(dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        date.getFullYear()
    ].join('/') + ' ' + timeToString(date);
   
}

export const dateTimeToJson = (aDateTime) => {
    return aDateTime ? dateToJson(aDateTime) + 'T' + timeToJson(aDateTime) : null;
};

export const dateToJson = (aDate) => {
    if (aDate) {
        var dd = aDate.getDate();
        var mm = aDate.getMonth() + 1;
        return [aDate.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
        ].join('-');
    }
    return null;
};

const timeToJson = (aTime) => {
    return timeToString(aTime)+'Z';
};

const timeToString = (aTime) => {
    let HH = aTime.getHours();
    let min = aTime.getMinutes();
    let ss = aTime.getSeconds();
    return [(HH > 9 ? '' : '0') + HH,
                    (min > 9 ? '' : '0') + min,
                    (ss > 9 ? '' : '0') + ss
                ].join(':');
}

export const dateToIntPeriod = (aDate) => aDate ? aDate.getFullYear()*100 + aDate.getMonth() + 1 : 0

/**
 * Devuelve la fecha relativa según el tiempo transcurrido o el faltante en formato array
 */
export const relativeDate = (aDate) => {
    let ahora = new Date()
    let years = 0
    let months = 0
    let days = (aDate-ahora)/(1000*60*60*24)
    let direccion = days
    days = Math.abs(Math.round(days))
    while (days > 31) {
        days -= 31
        ++months
    }
    while (months > 12) {
        months -= 12
        ++years
    }
    if (years > 0) {
        return [direccion > 0 ? 'TXT_EN' : 'TXT_HACE', years, years > 1 ? 'TXT_ANIOS' : 'TXT_ANIO']
    }
    if (months > 0) {
        return [direccion > 0 ? 'TXT_EN' : 'TXT_HACE', months, months > 1 ? 'TXT_MESES' : 'TXT_MES']
    }
    if (days === 0) return [direccion > 0 ? 'TXT_EN' : 'TXT_HACE', 'TXT_HORAS']
    if (days > 0) {
        return [direccion > 0 ? 'TXT_EN' : 'TXT_HACE', days, days > 1 ? 'TXT_DIAS' : 'TXT_DIA']
    }
}