import React from 'react'
import { DataTable } from '../../comp/DataTableV2'
import { Detalle } from './Detalle'
import { Filtros } from './Filtros'
import { Messages } from '../../comp/Messages2'
import { ModalDelete } from '../../comp/ModalDelete'
import { borrarProductos, listarProductos, reactivarProductos } from '../../services/servProductos'
import { Acciones } from '../../utils/Acciones'
import { TipoDato, NivelAcceso } from '../../utils/Constantes'
import { Acceso } from '../../utils/Seguridad'

const vista = 'ViewProductos'

export const Productos = () => {

    const puedeVerPrecioCompra = Acceso('ViewPedidosProveedores', NivelAcceso.VER)
    || Acceso(vista, NivelAcceso.AGREGAR)
    const puedeVerPrecioVenta = Acceso('ViewVentasPorCaja', NivelAcceso.AGREGAR)
    
    const tableConfig = React.useMemo(() => {
        let auxConfig = {
            title: vista,
            headers: ['TXT_CODIGO', 'TXT_NOMBRE', 'TXT_STOCK'],
            types: [TipoDato.CODIGO, TipoDato.CADENA, TipoDato.CANTIDAD],
            access: ['codigo', 'nombre', 'cantidad'],
            rowId: 'id'
        }

        if (puedeVerPrecioCompra) {
            auxConfig.headers.push('TXT_COSTO_MIN')
            auxConfig.types.push(TipoDato.IMPORTE)
            auxConfig.access.push('precioCosto')
        }
        if (puedeVerPrecioVenta) {
            auxConfig.headers.push('TXT_VENTA_MIN')
            auxConfig.types.push(TipoDato.IMPORTE)
            auxConfig.access.push('precioVenta')
        }
        return auxConfig
    }, [puedeVerPrecioCompra, puedeVerPrecioVenta])

    const [tData, setTData] = React.useState([])
    const [filters, setFilters] = React.useState({ codigo: null, nombre: '', categoriaId: null, activo: true})
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isTableLoading, setIsTableLoading] = React.useState(true)
    const [seleccion, setSeleccion] = React.useState([])
    const [modalAction, setModalAction] = React.useState(Acciones().SEE)
    const [resServicio, setResServicio] = React.useState()

    //calculo permisos de acceso a la vista
    const puedeAgregar = Acceso(vista, NivelAcceso.AGREGAR)
    const puedeEditar = Acceso(vista, NivelAcceso.EDITAR)
    const puedeBorrarReinsertar = Acceso(vista, NivelAcceso.BORRAR_REINSERTAR)

    const _handleOpenSearch = () => setIsSearchOpened(true)

    const _handleOpenDetail = (action, selection) => {
        setModalAction(action)
        setResServicio()
        setSeleccion(selection ? tData.filter(e => (selection.length && selection.indexOf(e.id) > -1) || e.id === selection) : [])
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setIsDelReacOpened(true)
        } else {
            setIsDetailOpened(true)
        }
    }

    const handleSearchSubmit = (submit, filtros) => {
        setIsSearchOpened(false)
        if (submit) {
            setFilters(filtros)
        }
    }

    const handleDetailSubmit = (obj) => {
        setIsDetailOpened(false)
        setSeleccion([])
        //si viene un objeto
        if (obj) {
            if (modalAction === Acciones().ADD) { //si es alta: lo dejo solo en la tabla
                setTData([obj])
            } else { //si edición: reemplazo el item seleccionado en la tabla
                setTData(tData.map(e => {return e.id === obj.id ? obj : e}))
            }
        }
    }

    const handleDelReacSubmit = async (submit) => {
        if (!submit) return setIsDelReacOpened(false)
        let resOp = (modalAction === Acciones().DEL)
                        ? await borrarProductos(seleccion.map(e => e.id))
                        : await reactivarProductos(seleccion.map(e => e.id))
        setResServicio(resOp)
        if (resOp.status === 200) {
            setTData(tData.map(e =>
                    seleccion.some(sel => sel.id === e.id)
                        ? { ...e, activo: modalAction === Acciones().REAC }
                        : e
            ))
            setIsDelReacOpened(false)
            setSeleccion([])
        }
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        setResServicio()
        listarProductos(filters).then(resListar => {
            setTData(resListar.data)
            resListar.errores && setResServicio(resListar)
            setIsTableLoading(false)
        })
    }, [filters])

    return <>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tData}
            openSearch={_handleOpenSearch}
            openDetail={_handleOpenDetail}
            isLoading={isTableLoading}
            hasAdd={puedeAgregar}
            hasEdit={puedeEditar}
            hasDel={puedeBorrarReinsertar}
            hasReac={puedeBorrarReinsertar} />
        <Filtros isOpen={isSearchOpened}
            onSubmit={handleSearchSubmit}
            filtros={filters} />
        <Detalle isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={seleccion[0]}
            accion={modalAction}
            verPrecioCompra={puedeVerPrecioCompra}
            verPrecioVenta={puedeVerPrecioVenta} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={seleccion.map(e => e.nombre)}
            action={modalAction}
            onSubmit={handleDelReacSubmit} />
        <Messages resServicio={resServicio} accion={modalAction}/>
    </>
    
}