import { ResumenCierre } from "./resumenCierre"
import { DataTable } from '../../comp/DataTableSimple'
import { TipoDato } from '../../utils/Constantes'

export const ResumenConStockToPrint = ({ datosCajero = [], datosCaja = [], datosStock = [] }) => {
    let datosCaja1 = [{ "formaDePago": { "id": 0, "nombre": "Saldo apertura" }, "totalCobrado": 1 }, { "formaDePago": { "id": 1, "nombre": "Efectivo" }, "totalCobrado": 23, "motivo": { "id": 1, "nombre": "Venta por caja" } }]
    return <>
        <DataTable headers={['TXT_CAJERO']}
            types={[TipoDato.CADENA]}
            access={['nombre']}
            data={datosCajero}
            size="small" />
        <br/><br/>
        <ResumenCierre datos={datosCaja1} isLoading={false} toPrint={true}/>
        <br/><br/>
        <DataTable headers={['TXT_CODIGO', 'TXT_NOMBRE', 'TXT_STOCK']}
            types={[TipoDato.CODIGO, TipoDato.CADENA, TipoDato.CANTIDAD]}
            access={['codigo', 'nombre', 'cantidad']}
            data={datosStock}
            size="small" />
    </>
}