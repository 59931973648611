import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable } from '../../comp/DataTableV2'
import { Traduccion } from '../../comp/Traduccion'

import { setCajaOperada } from '../../config/Sesion'

import { Acceso, NivelAcceso } from '../../utils/Seguridad'
import { Acciones } from '../../utils/Acciones'
import { TipoDato } from '../../utils/Constantes'

import { Detalle, Filtros } from './contenido'
import { abrirCaja, listarCajas } from '../../services/servCajas'
import { Messages } from '../../comp/Messages2'

const vista = 'ViewCajas';
const txtAbierta = Traduccion('TXT_ABIERTA');
const txtCerrada = Traduccion('TXT_CERRADA');

export const Cajas = () => {

    const [tData, setTData] = React.useState([])
    const [filters, setFilters] = React.useState({ user: '', desde: null, hasta: null })
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [modalAction, setModalAction] = React.useState(Acciones().SEE)
    const [isLoading, setIsLoading] = React.useState(false)
    const [seleccion, setSeleccion] = React.useState([])
    const [resServicio, setResServicio] = React.useState()

    const navigate = useNavigate()

    const _handleOpenDetail = (action, selection) => {
        var seleccion = selection ? tData.filter(e => e.id === selection) : {};
        if (action === Acciones().SEE) {
            setCajaOperada(seleccion.length > 0 ? seleccion[0] : {});
            navigate('/cajas/movimientos/');
        } else {
            setIsDetailOpened(true)
            setModalAction(action)
            setSeleccion(seleccion)
        }
    }

    const _handleSearchSubmit = (submit, filtros) => {
        setIsSearchOpened(false)
        if (submit) {
            setFilters(filtros)
        }
    }

    const _handleDetailSubmit = (submit, obj) => {
        if (!submit) return setIsDetailOpened(false)
        setIsLoading(true)
        if (modalAction === Acciones().ADD) {
            abrirCaja(obj).then(respOpen => {
                setResServicio(respOpen)
                if (respOpen.errores) return 
                listarCajas({ id: respOpen.data.id }).then(respDatosCaja => {
                    setCajaOperada(respDatosCaja.data[0]);
                    navigate('/cajas/movimientos/nuevo');
                })
            })
        }
    }

    const _search = (filtros) => {
        setIsTableLoading(true)
        setResServicio()
        listarCajas(filtros).then(resListar => {
            setTData(resListar.data?.map(e => { return { ...e, estado: e.activo ? txtAbierta : txtCerrada } }))
            resListar.errores && setResServicio(resListar)
            setIsTableLoading(false)
        })
    }

    React.useEffect(() => {
        _search(filters)
    }, [filters])

    return <>
        <DataTable title={vista}
            headers={['TXT_CAJERO', 'TXT_PUNTO_DE_VENTA', 'TXT_FECHA_APERTURA', 'TXT_ESTADO']}
            types={[TipoDato.CADENA, TipoDato.NUMERO, TipoDato.FECHA, TipoDato.CADENA]}
            access={['usuario.nombre', 'puntoDeVenta.numero', 'apertura', 'estado']}
            data={tData}
            rowId="id"
            openSearch={() => setIsSearchOpened(true)}
            openDetail={_handleOpenDetail}
            hasAdd={Acceso(vista, NivelAcceso().AGREGAR)}
            isLoading={isTableLoading}
            showStatusTootip={false} />
        <Filtros values={filters}
            isOpen={isSearchOpened}
            onSubmit={_handleSearchSubmit} />
        <Detalle isOpen={isDetailOpened}
            onSubmit={_handleDetailSubmit}
            seleccion={seleccion[0]}
            isSubmiting={isLoading} />
        <Messages resServicio={resServicio} accion={modalAction} />
    </>

}