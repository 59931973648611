import React from 'react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, 
        Grid, 
        Stack,
        Tooltip} from '@mui/material'
import CalculateIcon from '@mui/icons-material/Calculate'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Traduccion } from '../../../../comp/Traduccion'
import { FormatImporte } from '../../../../utils/Formatter'
import { Config } from '../../../../config/Sesion'

export const Resumen = ({totalVendido = 0, totalCobrado = 0, stepHandler, readOnly}) => {

    const _irAlPaso1 = () => stepHandler(1)
    const vuelto = () => Math.round((totalCobrado - totalVendido)*Math.pow(10, Config().DECIMALES_IMPORTE))/Math.pow(10, Config().DECIMALES_IMPORTE)

    return (
            <Card>
                <CardHeader title={Traduccion('TXT_RESUMEN')} />
                <CardContent>
                    <Stack direction='column' alignItems='flex-end' justifyContent='flex-start' spacing={2} useFlexGap>
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_TOTAL_VENDIDO')}>
                                    <div>{FormatImporte(totalVendido)}</div>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_TOTAL_VENDIDO')}>
                                    <ShoppingCartIcon />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        { readOnly &&
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_TOTAL_COBRADO')}>
                                    <div>{FormatImporte(totalCobrado)}</div>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_TOTAL_COBRADO')}>
                                    <LocalAtmIcon />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        }
                        { readOnly && 
                        <Divider variant="inset" flexItem/>
                        }
                        { readOnly &&
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_VUELTO')}>
                                    <Box sx={vuelto() < 0 ? {color: 'red'} : {}}>{FormatImporte(vuelto())}</Box>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={Traduccion('TXT_VUELTO')}>
                                    <CalculateIcon />
                                </Tooltip>
                            </Grid>
                        </Grid>
                        }
                    </Stack>
                </CardContent>
                { !readOnly &&
                <CardActions disableSpacing>
                    <Button id="btnCobrar" onClick={_irAlPaso1} color="primary" size="small" style={{marginLeft: 'auto'}}>
                        {Traduccion('TXT_COBRAR')}
                    </Button>
                </CardActions>
                }
            </Card>
    )
}