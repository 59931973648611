import React from 'react'
import { DataTable } from '../../comp/DataTableSimple'

import { TipoDato } from '../../utils/Constantes'

export const ResumenCierre = ({datos, isLoading, toPrint = false}) => {
    return <DataTable headers={['TXT_FORMA_DE_PAGO', 'TXT_IMPORTE']}
               types={[TipoDato.CADENA, TipoDato.IMPORTE, TipoDato.IMPORTE, TipoDato.IMPORTE]}
               access={['formaDePago.nombre', 'totalCobrado']}
               data={datos}
               isLoading={isLoading}
               totalizar={-1}
               size={toPrint ? 'small' : undefined}/>
}