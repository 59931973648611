import React from 'react'
import { DataTable } from '../../comp/DataTableV2'
import {
    listarUsuarios,
    borrarUsuarios,
    reactivarUsuarios
} from '../../services/servUsuarios'
import { ModalDelete } from '../../comp/ModalDelete'
import { Acciones } from '../../utils/Acciones'
import { TipoDato } from '../../utils/Constantes'
import { Detalle, Filtros, ModalTransacciones } from './contenido'

export const Usuarios = () => {

    const tableConfig = {
        title: 'ViewUsuarios',
        headers: ['TXT_APELLIDOS', 'TXT_NOMBRES', 'TXT_CORREO', 'TXT_ROLES'],
        types: [TipoDato.CADENA, TipoDato.CADENA, TipoDato.CADENA, TipoDato.CADENA],
        access: ['apellidos', 'nombres', 'email', 'roles'],
        rowId: 'id'
    }

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)
    const [isSearchOpened, setIsSearchOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])
    const [tableFilters, setTableFilters] = React.useState({activo: true})

    const [isTransaccionesOpen, setIsTransaccionesOpen] = React.useState(false)
    const [resServicio, setResServicio] = React.useState()
    

    const handleOpenDetail = (action, selection) => {
        setDetailAction(action)
        setResServicio()
        setTableSelection(selection ? tableData.filter(e => (selection.length && selection.indexOf(e.id) > -1) || e.id === selection) : [])
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setIsDelReacOpened(true)
        } else {
            setIsDetailOpened(true)
        }
    }

    const handleSearchSubmit = (submit, filters) => {
        setIsSearchOpened(false)
        if (submit) {
            setTableFilters(filters);
        }
    }

    const handleDetailSubmit = (obj) => {
        setIsDetailOpened(false)
        setTableSelection([])
        //si viene un objeto
        if (obj) {
            if (detailAction === Acciones().ADD) { //si es alta: lo dejo solo en la tabla
                setTableData([obj])
            } else { //si edición: reemplazo el item seleccionado en la tabla
                setTableData(tableData.map(e => {return e.id === obj.id ? obj : e}))
            }
        }
    }
    
    const handleDelReacSubmit = async (submit) => {
        if (!submit) return setIsDelReacOpened(false)
        let resOp = (detailAction === Acciones().DEL)
                        ? await borrarUsuarios(tableSelection.map(e => e.id))
                        : await reactivarUsuarios(tableSelection.map(e => e.id))
        setResServicio(resOp)
        if (resOp.status === 200) {
            setTableData(tableData.map(e =>
                    tableSelection.some(sel => sel.id === e.id)
                        ? { ...e, activo: detailAction === Acciones().REAC }
                        : e
            ))
            setIsDelReacOpened(false)
            setTableSelection([])
        }
    }

    const formatDataForTable = (datos) => {
        return //agregar funcionalidad si hace falta
    }

    const search = (filtros) => {
        setIsTableLoading(true)
        setResServicio()
        listarUsuarios(filtros).then(res => {
            formatDataForTable(res.data)
            setTableData(res.data)
            res.errores && setResServicio(res)
            setIsTableLoading(false)
        })
    }

    React.useEffect(() => {
        search(tableFilters)
    }, [tableFilters])

    return <div>
        <DataTable title={tableConfig.title}
            headers={tableConfig.headers}
            types={tableConfig.types}
            access={tableConfig.access}
            rowId={tableConfig.rowId}
            data={tableData}
            isLoading={isTableLoading}
            openDetail={handleOpenDetail}
            openSearch={setIsSearchOpened}
            hasAdd hasEdit hasReac hasDel />
        <Filtros isOpen={isSearchOpened}
                 onSubmit={handleSearchSubmit}
                 filtros={tableFilters}/>
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <ModalTransacciones isOpen={isTransaccionesOpen}
            seleccion={tableSelection[0]}
            onSubmit={e => setIsTransaccionesOpen(false)} />
    </div>
}