import React from 'react'
import { TableCell,
        TableRow,
        Skeleton
        }  from '@mui/material'
import { PropTypes } from 'prop-types'

import { Traduccion } from './Traduccion'

import { FormatFechaHora, FormatImporte, FormatCantidad } from '../utils/Formatter'
import { TipoDato } from '../utils/Constantes'

const LoadingRow = ({span}) => {
    return (
            <TableRow>
                <TableCell colSpan={span}>
                    <Skeleton variant="rect" animation="wave" 
                              height={'1.5em'}
                              />
                </TableCell>
            </TableRow>
            );
};

export const LoadingRows = ({span}) => {
    return [
        <LoadingRow key="1" span={span}/>,
        <LoadingRow key="2" span={span}/>,
        <LoadingRow key="3" span={span}/>
    ];
};

LoadingRows.propTypes = {
    span: PropTypes.number.isRequired
};

export const EmptyRow = ({span}) => {
    return (
            <TableRow>
                <TableCell colSpan={span}>{Traduccion('MSJ_NO_HAY_REGISTROS')}</TableCell>
            </TableRow>
            );
};

EmptyRow.propTypes = {
    span: PropTypes.number.isRequired
};

const getValFormateado = (valor, tipoDato) => {
    switch (tipoDato) {
        case TipoDato.FECHA: return FormatFechaHora(valor);
        case TipoDato.IMPORTE: return FormatImporte(valor);
        case TipoDato.CANTIDAD: return FormatCantidad(valor);
        default: return valor;
    }
};
const getValFromRuta = (obj, ruta) => (ruta.length===1)? obj[ruta] : getValFromRuta(obj[ruta.shift()], ruta);
const getCellVal = (obj, ruta, tipoDato) => getValFormateado(getValFromRuta(obj, ruta.split('.')), tipoDato);
const getAlineacion = (type) => type === TipoDato.CADENA ? 'left' : 'right';

export const CellBody = ({tipo, ruta, children}) => {
    return <TableCell align={getAlineacion(tipo)}>{getCellVal(children,ruta,tipo)}</TableCell>;
};

export const CellHead = ({tipo, traducir, children}) => {
    return <TableCell align={getAlineacion(tipo)}><b>{traducir ? Traduccion(children) : children}</b></TableCell>;
};

export const CellFoot = ({tipo, ruta, children}) => {
    return <TableCell align={getAlineacion(tipo)}><b>
    {
        getValFormateado(
                children
                    .map(obj => getValFromRuta(obj, ruta.split('.')))
                    .reduce((sum, num) => sum + num),
                tipo
        )
    }
    </b>
    </TableCell>;
};

export const CellFootTotal = ({span}) => {
    return <TableCell colSpan={span} align={getAlineacion(TipoDato.IMPORTE)}><b>{Traduccion('TXT_TOTAL')}</b></TableCell>;
};