import React from 'react'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import TextField from '@mui/material/TextField'
import { PropTypes } from 'prop-types'
import { NumericFormat } from 'react-number-format'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { Config } from '../config/Sesion'
import { Traduccion } from './Traduccion'

const NumberFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, prefijo, separarMiles, decimales = 0, ...other } = props
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            id: props.id,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={separarMiles && Config().SEPARADOR_DE_MILES}
                decimalSeparator={Config().SEPARADOR_DECIMAL}
                decimalScale={decimales}
                fixedDecimalScale={true}
                prefix={prefijo}
            />
        )
    }
)

NumberFormatCustom.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export const InputCantidad = ({ id, label = '', value = null, onChange, editable = true, autoFocus, requerido = false, validarSubmit = false }) => {

    const hayError = () => validarSubmit && requerido && !value && value !== 0

    return <TextField id={id}
        label={label}
        required={requerido}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        value={value}
        onChange={e => onChange && onChange(e.target.value * 1, id)}
        name={'cant_' + Math.random()}
        InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
                decimales: Config().DECIMALES_CANTIDAD,
                separarMiles: true
            }
        }}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={hayError() ? Traduccion('TXT_REQUERIDO') : ''}
        error={hayError()}
        fullWidth />
}

InputCantidad.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    //onChange: PropTypes.arrayOf(PropTypes.string).isRequired,
    editable: PropTypes.bool
};

export const InputImporte = ({ id, label, value, onChange, editable = true, autoFocus, requerido = false, validarSubmit = false, noCero }) => {
    //siempre dispara error si value está en [null, < 0, undefined]
    const hayError = () => validarSubmit && requerido && (!value || value < 0) && (noCero ? true : value !== 0)

    return <TextField id={id}
        label={label}
        required={requerido}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        value={value}
        onChange={e => onChange && onChange(e.target.value * 1, id)}
        name={'importe_' + Math.random()}
        InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
                decimales: Config().DECIMALES_IMPORTE,
                prefijo: Config().SIMBOLO_MONETARIO,
                separarMiles: true
            }
        }}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={hayError() ? Traduccion('TXT_REQUERIDO') : ''}
        error={hayError()}
        fullWidth />
}

InputImporte.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    //onChange: PropTypes.arrayOf(PropTypes.string).isRequired,
    editable: PropTypes.bool
};

export const InputCodigo = ({ id, label, value = null, onChange, autoFocus, editable = true, digits, requerido = false, validarSubmit = false }) => {
   return <TextField id={id}
        label={label}
        required={requerido}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        value={value}
        onChange={e => onChange && onChange(e.target.value, id)}
        name={'codigo_' + Math.random()}
        InputProps={{
            inputComponent: NumberFormatCustom
        }}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={validarSubmit && requerido && !value ? Traduccion('TXT_REQUERIDO') : ''}
        error={validarSubmit && requerido && !value}
        fullWidth />
}

InputCodigo.propTypes = {
    label: PropTypes.string.isRequired,
    //value: PropTypes.number,
    //onChange: PropTypes.arrayOf(PropTypes.string).isRequired,
    editable: PropTypes.bool
}

export const InputEntero = ({ id, label, value = null, onChange, autoFocus, editable = true, digits, requerido = false, validarSubmit = false }) => {
    
    const hayError = () => validarSubmit && requerido && !value && value !== 0
    
    return <TextField id={id}
        label={label}
        required={requerido}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        value={value}
        onChange={e => onChange && onChange(e.target.value === '' ? null : e.target.value, id)}
        InputProps={{
            inputComponent: NumberFormatCustom
        }}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={hayError() ? Traduccion('TXT_REQUERIDO') : ''}
        error={hayError()}
        fullWidth />
}

InputEntero.propTypes = {
    label: PropTypes.string.isRequired,
    editable: PropTypes.bool
};

export const InputTexto = ({ id, label, value, onChange, editable = true, autoFocus, requerido = false, validarSubmit = false, maxLength = 8000 }) => {
    return <TextField id={id}
        label={label}
        required={requerido}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        value={value ? value : ''}
        onChange={e => onChange(e.target.value)}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={validarSubmit && requerido && !value ? Traduccion('TXT_REQUERIDO') : ''}
        error={validarSubmit && requerido && !value}
        maxLength={maxLength}
        fullWidth />
}

InputTexto.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    //onChange: PropTypes.arrayOf(PropTypes.string).isRequired,
    editable: PropTypes.bool
};

export const InputParrafo = ({ id, label, value, onChange, editable, autoFocus, maxLength }) => {

    const [error, setError] = React.useState(false);

    const _onChange = (valor) => {
        if (valor.length > maxLength) {
            setError(true);
        } else {
            setError(false);
            onChange(valor);
        }
    };

    return <TextField  id={id}
        label={label}
        error={error}
        margin="dense"
        variant="outlined"
        disabled={!editable}
        multiline
        rows={4}
        value={value ? value : ''}
        onChange={e => _onChange(e.target.value)}
        autoFocus={autoFocus}
        onFocus={e => e.target.select()}
        helperText={(value ? value.length : 0) + '/' + maxLength}
        fullWidth />
}

InputParrafo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    editable: PropTypes.bool,
    maxLength: PropTypes.number.isRequired
}

export const InputCheckbox = ({ id, label, value, onChange, editable = true, autoFocus }) => {
    return (
        <FormControlLabel
            label={label}
            disabled={!editable}
            control={
                <Checkbox id={id}
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    autoFocus={autoFocus} />
            }
        />
    )
}

export const InputFile = ({ id, file, onFileSelect, editable}) => {
    return <div>
        <Button id={id}
            color="secondary" 
            variant="contained"
            component='label'
            disabled={!editable}
            startIcon={<AttachFileIcon />}>
                {Traduccion('TXT_ADJUNTAR')}
                <input type="file" hidden onChange={onFileSelect}/>
        </Button>
        &nbsp;
        {
            file ? file.name : <i>{Traduccion('TXT_NINGUN_ARCHIVO_SELECCIONADO')}</i>
        }
    </div>
}