import React from "react"
import { DataTable } from "../../../comp/DataTable"
import { ModalDelete } from "../../../comp/ModalDelete"
import { Traduccion } from "../../../comp/Traduccion"
import { Acciones } from "../../../utils/Acciones"
import { TipoDato } from '../../../utils/Constantes'
import { Detalle } from "./contenido"
import { agregarFormaDePago, borrarFormasDePago, listarFormasDePago, modificarFormaDePago, reactivarFormasDePago } from "../../../services/servCajas"
import { Messages } from "../../../comp/Messages2"

export const FormasDeCobro = () => {

    const [detailAction, setDetailAction] = React.useState(Acciones.SEE)
    const [isDelReacOpened, setIsDelReacOpened] = React.useState(false)
    const [isDetailOpened, setIsDetailOpened] = React.useState(false)

    const [isTableLoading, setIsTableLoading] = React.useState(false)
    const [tableData, setTableData] = React.useState([])
    const [tableSelection, setTableSelection] = React.useState([])

    const [resServicio, setResServicio] = React.useState()

    const handleDetailOpen = (action, selection) => {
        setResServicio()
        setDetailAction(action)
        if ([Acciones().DEL, Acciones().REAC].includes(action)) {
            setTableSelection(selection ? tableData.filter(e => selection.indexOf(e.id) > -1) : [])
            setIsDelReacOpened(true)
        } else {
            setTableSelection(selection ? tableData.filter(e => e.id === selection) : [{}])
            setIsDetailOpened(true)
        }
    }

    const handleDetailSubmit = async (submitted, obj) => {
        if (!submitted) {
            setIsDetailOpened(false)
            setIsDelReacOpened(false)
            return
        }
        let res
        switch (detailAction) {
            case Acciones().ADD:
                res = await agregarFormaDePago(obj)
                if (!res.errores) {
                    obj.id = res.data.id
                    obj.activo = true
                    tableData.unshift(obj)
                }
                break
            case Acciones().EDIT:
                res = await modificarFormaDePago(obj)
                if (!res.errores) tableSelection[0].nombre = obj.nombre
                break
            case Acciones().DEL:
                res = await borrarFormasDePago(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = false })
                break
            case Acciones().REAC:
                res = await reactivarFormasDePago(tableSelection.map(e => e.id))
                if (!res.errores) tableSelection.forEach(e => { e.activo = true })
                break
            default: return
        }
        setResServicio(res)
        setTableSelection([])
        setIsDetailOpened(false)
        setIsDelReacOpened(false)
    }

    const formatDataForTable = (datos = []) => {
        datos.forEach(e => {
            e.nombre = e.id > 0 ? e.nombre : Traduccion(e.nombre)
        })
        datos.sort((e1, e2) => e1.nombre.localeCompare(e2.nombre))
    }

    React.useEffect(() => {
        setIsTableLoading(true)
        setResServicio()
        listarFormasDePago().then(res => {
            formatDataForTable(res.data)
            setTableData(res.data)
            res.errores && setResServicio(res)
            setIsTableLoading(false)
        })
    }, [])

    return <>
        <DataTable title='TXT_FORMAS_DE_PAGO'
            headers={['TXT_NOMBRE']}
            types={[TipoDato.CADENA]}
            access={['nombre']}
            data={tableData}
            isLoading={isTableLoading}
            rowId="id"
            openDetail={handleDetailOpen}
            hasAdd hasEdit hasReac hasDel />
        <Detalle accion={detailAction}
            isOpen={isDetailOpened}
            onSubmit={handleDetailSubmit}
            seleccion={tableSelection[0]} />
        <ModalDelete isOpen={isDelReacOpened}
            seleccion={tableSelection.map(e => e.nombre)}
            action={detailAction}
            onSubmit={handleDetailSubmit} />
        <Messages resServicio={resServicio} accion={detailAction} />
    </>
}